import React, { useMemo, useState } from 'react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import PropTypes from 'prop-types';
import { Divider, FormControl, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import toNumber from 'lodash/toNumber';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { FingoDatePicker } from '@fingo/lib/components/datePickers';
import { formatGraphQlDate } from '@fingo/lib/helpers';
import { useLazyQuery } from '@apollo/client';
import { GET_PAYMENT_PLAN } from '@fingo/lib/graphql/payment_plans/query';
import { MoneyInput } from '@fingo/lib/components/inputs';

const InstallmentCreationHelper = ({
  open,
  setOpen,
  targetAmount,
  _setInstallments,
  setRecalculateInstallments,
  setTargetAmount,
  setInterestRate,
}) => {
  const emptyInstallment = {
    amortizationToPay: 0,
    paymentDate: null,
  };
  const [upfrontPayment, _setUpfrontPayment] = useState(emptyInstallment);
  const [principalAmount, setPrincipalAmount] = useState(targetAmount);
  const [upfrontPaymentAmountPercentage, _setUpfrontPaymentAmountPercentage] = useState(0);
  const [datePolicy, setDatePolicy] = useState('months');
  const [installmentsNumber, setInstallmentNumber] = useState(0);
  const [monthlyRate, setMonthlyRate] = useState(0);
  const [weekDifference, setWeekDifference] = useState(1);
  const [startingDay, setStartingDay] = useState(moment());
  const [upfrontPaymentDay, setUpfrontPaymentDay] = useState(moment());
  const setUpfrontPayment = (value) => {
    _setUpfrontPayment((old) => ({ ...old, amortizationToPay: value }));
    _setUpfrontPaymentAmountPercentage(((value || 0) / principalAmount) * 100);
  };
  const setUpfrontPaymentAmountPercentage = (value) => {
    _setUpfrontPaymentAmountPercentage(value);
    _setUpfrontPayment((old) => ({ ...old, amortizationToPay: (principalAmount * value) / 100 }));
  };
  const upfrontAmount = useMemo(
    () => (upfrontPayment ? parseInt(upfrontPayment.amortizationToPay, 10) : 0),
    [upfrontPayment],
  );
  const [getPaymentPlan, { loading }] = useLazyQuery(GET_PAYMENT_PLAN, {
    onCompleted: ({ getPaymentPlan: data }) => {
      _setInstallments(
        data.map(({ amortizationToPay, paymentDate }) => ({
          amortizationToPay,
          paymentDate: moment(paymentDate, 'YYYY-MM-DD'),
        })),
      );
      setTargetAmount(principalAmount);
      setInterestRate(monthlyRate);
      setRecalculateInstallments((old) => !old);
      setOpen(false);
    },
  });

  return (
    <FingoDialog
      open={open}
      title="Asistente para cuotas"
      handleClose={() => setOpen(false)}
      PaperProps={{ sx: { borderRadius: 4, minWidth: '60%' } }}
    >
      <Stack spacing={2}>
        <MoneyInput
          size="small"
          label="Meta"
          variant="outlined"
          value={principalAmount}
          sx={{ width: '180px' }}
          setValue={setPrincipalAmount}
        />
        <Divider />
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography width="180px">
            Upfront
          </Typography>
          <MoneyInput
            size="small"
            label="Monto de la cuota"
            variant="outlined"
            value={upfrontPayment.amortizationToPay}
            sx={{ width: '180px' }}
            setValue={setUpfrontPayment}
          />
          <TextField
            size="small"
            label="Porcentaje sobre el total"
            value={upfrontPaymentAmountPercentage}
            sx={{ width: '180px' }}
            onChange={({ target }) => setUpfrontPaymentAmountPercentage(target.value)}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100, step: 0.01 },
            }}
          />
          <FingoDatePicker
            label="Fecha de pago"
            value={upfrontPaymentDay}
            width="180px"
            onChange={(value) => setUpfrontPaymentDay(moment(value))}
          />
        </Stack>
        <Divider />
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography width="180px">
            Número de cuotas e interés
          </Typography>
          <TextField
            size="small"
            type="number"
            variant="outlined"
            label="# cuotas"
            value={installmentsNumber}
            sx={{ width: '180px' }}
            onChange={({ target }) => setInstallmentNumber(
              target.value && toNumber(target.value),
            )}
          />
          <TextField
            size="small"
            type="number"
            label="Tasa de interés"
            variant="outlined"
            value={monthlyRate}
            sx={{ width: '180px' }}
            onChange={({ target }) => setMonthlyRate(target.value && toNumber(target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { min: 0, max: 100, step: 0.01 },
              padding: 0,
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography width="180px">
            Estructura de cuotas
          </Typography>
          <TextField
            size="small"
            type="number"
            variant="outlined"
            label="Cada"
            value={weekDifference}
            sx={{ width: '180px' }}
            onChange={({ target }) => setWeekDifference(target.value)}
          />
          <FormControl variant="outlined" sx={{ width: '180px' }}>
            <Select
              name="datePolicy"
              value={datePolicy}
              onChange={({ target }) => { setDatePolicy(target.value); }}
            >
              <MenuItem value="weeks">Semanas</MenuItem>
              <MenuItem value="months">Meses</MenuItem>
            </Select>
          </FormControl>
          <FingoDatePicker
            label="Fecha primera cuota"
            value={startingDay}
            width="180px"
            onChange={(value) => setStartingDay(moment(value))}
          />
        </Stack>
        <Divider />
        <LoadingButton
          size="small"
          loading={loading}
          variant="contained"
          sx={{ width: '240px', alignSelf: 'end' }}
          disabled={!(upfrontAmount + installmentsNumber)}
          onClick={() => getPaymentPlan({ variables: {
            principalAmount,
            monthlyRate,
            upfrontAmount,
            upfrontPaymentDate: formatGraphQlDate(upfrontPaymentDay),
            installmentDates: Array.from(Array(installmentsNumber)).map(
              (_, i) => {
                const dateCopy = startingDay.clone();
                return formatGraphQlDate(dateCopy.add(i + 1, datePolicy));
              },
            ),
          } })}
        >
          Créer une collection 🇫🇷
        </LoadingButton>
      </Stack>
    </FingoDialog>
  );
};
InstallmentCreationHelper.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  _setInstallments: PropTypes.func.isRequired,
  setRecalculateInstallments: PropTypes.func.isRequired,
  targetAmount: PropTypes.number.isRequired,
  setTargetAmount: PropTypes.func.isRequired,
  setInterestRate: PropTypes.func.isRequired,
};

export default InstallmentCreationHelper;
