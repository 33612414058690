import { debtorCollectionPriorityFilter } from '@fingo/lib/apollo/reactive-variables';
import { setDebtorCollectionPriorityFilter } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { useMasterEntityPreColumns } from '@fingo/lib/constants';
import { getFilterByNameFromHistory } from '@fingo/lib/helpers';
import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import PropTypes from 'prop-types';
import useCollectionDebtorColumns from '../constants/collection-debtor-columns';
import DebtorsActions from '../components/Debtors/DebtorsActions';

const Debtors = ({ documentType, queryDocument, title }) => {
  const history = useHistory();
  const columns = useMasterEntityPreColumns(useCollectionDebtorColumns(documentType));
  const [priority, _setPriority] = useState(
    getFilterByNameFromHistory(history, 'debtor_CollectionPriorities_Priority_Value')
    || debtorCollectionPriorityFilter(),
  );
  const setPriority = (priorityFilter) => {
    setDebtorCollectionPriorityFilter(priorityFilter);
    _setPriority(priorityFilter);
  };
  const country = useGetCountryFromUrl();
  return (
    <FingoMainView
      id="debtors-list"
      query={queryDocument}
      queryCustomVariables={{
        inCollection: 77201556,
        debtor_CollectionPriorities_Priority_Value: priority,
        countryId: country?.id,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
        actions: DebtorsActions,
      }}
      slotProps={{
        header: {
          viewTitle: title,
          finder: {
            searchPlaceHolder: 'Buscar deudor',
          },
        },
        table: {
          columns,
          noRowsMessage: () => <Typography>Sin deudores</Typography>,
          initialOrderBy: 'debtor_CollectionPriorities_Priority_Value',
          includeHeaders: [
            'name',
            'debtor_Collector_FirstName',
            'documentsCount',
            'minDateToPay',
            'totalAmount',
            'debtor_CollectionPriorities_Priority_Value',
            'expiredAmount',
            'lastAction',
            'actionsDrawer',
          ],
          rowsPerPageOptions: [15, 25, 50, 100],
          initialPageSize: 50,
        },
        actions: {
          debtorCollectionPriority: priority,
          setDebtorCollectionPriority: setPriority,
        },
      }}
    />
  );
};

Debtors.propTypes = {
  documentType: PropTypes.oneOf(['ordering', 'invoice']).isRequired,
  queryDocument: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
};

export default Debtors;
