import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Grid, TextField, Typography } from '@mui/material';
import { ADD_COLLECTION_PROROGATION } from '@fingo/lib/graphql';
import { useSnackBars } from '@fingo/lib/hooks';

const useAddCollectionProrogationSteps = (debtor, documents) => {
  const [prorogationDays, setProrogationDays] = useState(0);
  const { addAlert } = useSnackBars();

  const resetFields = useCallback(() => {
    setProrogationDays(null);
  }, []);

  const collectionManagerIds = documents.map(({ collectionManager }) => collectionManager.id);
  const collectionManagersCount = collectionManagerIds.length;

  const [addCollectionProrogation, { loading }] = useMutation(ADD_COLLECTION_PROROGATION, {
    variables: {
      collectionManagerIds,
      prorogationDays,
    },
    onCompleted: () => {
      addAlert({
        id: 'add-collection-prorogation',
        message: 'Prórroga creada exitosamente',
      });
      resetFields();
    },
  });

  const steps = [
    {
      title: 'Prórroga',
      component: (
        <Grid container alignItems="center" direction="column" spacing={3}>
          <Grid item>
            <Typography variant="h5">
              {`¿Por cuántos días quieres extender la prórroga de ${
                collectionManagersCount > 1 ? 'estos' : 'este'
              } ${collectionManagersCount > 1 ? collectionManagersCount : ''} ${
                collectionManagersCount > 1 ? 'documentos' : 'documento'
              } de ${debtor?.name}?`}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              placeholder="Días de prórroga"
              sx={{ px: 0.3 }}
              type="number"
              onWheel={(e) => e.target.blur()}
              value={prorogationDays}
              onChange={(e) => setProrogationDays(e.target.value)}
            />
          </Grid>
        </Grid>
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: !prorogationDays,
        loading,
        handler: addCollectionProrogation,
        label: 'Asignar prórroga',
      },
    },
  ];

  return [steps, resetFields];
};

export default useAddCollectionProrogationSteps;
