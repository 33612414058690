import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ContactList } from '@fingo/lib/components/lists';
import { ControlledSelect } from '@fingo/lib/components/selects';
import { Container, Stack, Typography } from '@mui/material';
import { FingoDatePicker } from '@fingo/lib/components/datePickers';
import { ADD_DATA_FOR_COLLECTION, GET_PAYMENT_METHOD_ENUM } from '@fingo/lib/graphql';
import { useEnum, useSnackBars } from '@fingo/lib/hooks';
import moment from 'moment';
import { CONTACT_TYPES } from '@fingo/lib/constants';

const useAddDataForCollectionSteps = (debtor, documents) => {
  const [paymentMethod, setPaymentMethod] = useState();
  const [dateToPay, setDateToPay] = useState();
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [options] = useEnum(GET_PAYMENT_METHOD_ENUM);
  const { addAlert } = useSnackBars();

  const resetFields = useCallback(() => {
    setPaymentMethod(null);
    setDateToPay(null);
    setSelectedContactIds([]);
  }, []);

  const [addDataForCollection, { loading }] = useMutation(ADD_DATA_FOR_COLLECTION, {
    variables: {
      collectionManagerIds: documents.map(({ collectionManager }) => collectionManager.id),
      collectionContactIds: selectedContactIds,
      dateToPay,
      paymentMethod,
    },
    onCompleted: () => {
      addAlert({
        id: 0,
        color: 'primary',
        severity: 'success',
        message: 'Datos de recaudación guardados',
      });
      resetFields();
    },
  });

  const steps = [
    {
      title: 'Agregar datos de recaudación',
      component: (
        <Stack spacing={2} width={300}>
          <Container component="div" sx={{ width: '100%', p: '0 !important' }}>
            <Typography variant="formLabel">Fecha de pago acordada</Typography>
            <FingoDatePicker
              onChange={(value) => setDateToPay(moment(value).format('YYYY-MM-DD'))}
              value={dateToPay}
            />
          </Container>
          <ControlledSelect
            value={paymentMethod}
            handleChange={(e) => setPaymentMethod(e.target.value)}
            label="Método de pago"
            options={options}
          />
        </Stack>
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: !paymentMethod || !dateToPay,
        label: 'Siguiente',
      },
    },
    {
      title: 'Agregar datos de recaudación',
      component: (
        <ContactList
          showAllContactTypes
          masterEntity={debtor}
          contactType={CONTACT_TYPES.COLLECTION}
          setSelectedContactIds={setSelectedContactIds}
          selectedContactIds={selectedContactIds}
        />
      ),
      backButton: {
        label: 'Atrás',
      },
      nextButton: {
        disabled: selectedContactIds.length === 0,
        label: 'Guardar',
        loading,
        handler: addDataForCollection,
      },
      maxWidth: '70%',
      fullWidth: true,
    },
  ];

  return [steps, resetFields];
};

export default useAddDataForCollectionSteps;
