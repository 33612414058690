import React from 'react';
import { PaperHeader } from '@fingo/lib/components/headers';
import { useAvailableHeight } from '@fingo/lib/hooks';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { collectionSummaryAdaptData } from '@fingo/lib/constants/collection-summary';
import { CollectionSummaryBarChart, CollectionSummaryDataGrid } from '@fingo/lib/components/collection-summary';
import CollectionSummaryCards from '@fingo/lib/components/collection-summary/CollectionSummaryCards';
import useCollectionSummary from '../hooks/useCollectionSummary';

const CollectionSummary = () => {
  const [height, refChange] = useAvailableHeight(10);
  const {
    collectionManagersByDateRange,
    collectionManagersByExpiration,
    collectionManagersByPriority,
    loading,
  } = useCollectionSummary();

  const countsChartData = collectionSummaryAdaptData(
    collectionManagersByDateRange,
    'count',
  );
  const amountsChartData = collectionSummaryAdaptData(
    collectionManagersByDateRange,
    'amount',
  );

  if (loading) return <CircularProgress />;

  return (
    <>
      <PaperHeader viewTitle="Resumen de cobranza" />
      <Grid
        container
        sx={{
          bgcolor: 'background.light',
          borderRadius: 2,
          p: 2,
        }}
        columnSpacing={{ xs: 0, md: 1 }}
        ref={refChange}
        height={height}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{ borderRadius: 2 }}
          alignContent="flex-start"
          height="100%"
          rowGap={1}
        >
          <Stack height="100%" spacing={2}>
            <CollectionSummaryCards
              collectionManagersByExpiration={collectionManagersByExpiration}
            />
            <CollectionSummaryDataGrid
              collectionManagersByPriority={collectionManagersByPriority}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7} height="100%" direction="column">
          <CollectionSummaryBarChart
            chartData={amountsChartData}
            title="Monto en cartera"
            isMoney
          />
          <CollectionSummaryBarChart
            chartData={countsChartData}
            title="Documentos en cartera"
            legend
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CollectionSummary;
