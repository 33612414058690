/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { MasterEntityType } from '@fingo/lib/propTypes';
import { Button, Box, Stack, Typography } from '@mui/material';
import AddReactionOutlined from '@mui/icons-material/AddReactionOutlined';
import NoteAltOutlined from '@mui/icons-material/NoteAltOutlined';
import MailOutlined from '@mui/icons-material/MailOutlined';
import AlarmOutlined from '@mui/icons-material/AlarmOutlined';

import StepperDialog from '@fingo/lib/components/dialogs/StepperDialog';
import { useSendCollectionMailSteps } from '@fingo/lib/hooks';
import { CONTACT_COLUMNS } from '@fingo/lib/constants/contact-columns';
import {
  useAddCollectionActionSteps,
  useAddCollectionProrogationSteps,
  useAddDataForCollectionSteps,
} from '../../hooks';

const DebtorActions = ({ debtor, selectedDocumentIds, query }) => {
  const [openAddCollectionActionDialog, setOpenAddCollectionActionDialog] = useState(false);
  const [openSendCollectionMailDialog, setOpenSendCollectionMailDialog] = useState(false);
  const [openAddDataForCollectionDialog, setOpenAddDataForCollectionDialog] = useState(false);
  const [openAddCollectionProrogationDialog, setOpenAddCollectionProrogationDialog] =
    useState(false);

  const { data, loading } = useQuery(query, {
    variables: {
      id_In: selectedDocumentIds,
      first: selectedDocumentIds.length,
    },
    skip: !selectedDocumentIds.length,
  });

  const documents = Object.values(data || [])?.[0]?.edges?.map((doc) => doc?.node) || [];

  const [addCollectionActionSteps, addCollectionActionResetFields] = useAddCollectionActionSteps(
    debtor,
    documents,
  );
  const [addDataForCollectionSteps, addDataForCollectionResetFields] = useAddDataForCollectionSteps(
    debtor,
    documents,
  );
  const [sendCollectionMailSteps, sendCollectionMailResetFields] = useSendCollectionMailSteps(
    debtor,
    documents,
    true,
    true,
    CONTACT_COLUMNS,
  );
  const [addCollectionProrogationSteps, addCollectionProrogationResetFields] =
    useAddCollectionProrogationSteps(debtor, documents);

  return (
    <Box height="100%">
      <Stack sx={{ borderRadius: 2, px: 1, width: 'auto', height: 100 }} id="debtor-actions" direction="column" spacing="auto">
        <Button
          id="add-collection-action-button"
          color="primary"
          variant="contained"
          startIcon={<AddReactionOutlined />}
          sx={{ height: 20, width: '100%', justifyContent: 'flex-start' }}
          onClick={() => setOpenAddCollectionActionDialog(true)}
          disabled={selectedDocumentIds.length === 0 || loading}
        >
          <Typography noWrap>Nueva gestión</Typography>
        </Button>
        <Button
          id="send-collection-mail-button"
          color="primary"
          variant="contained"
          startIcon={<MailOutlined />}
          sx={{ height: 20, width: '100%', justifyContent: 'flex-start' }}
          onClick={() => setOpenSendCollectionMailDialog(true)}
          disabled={selectedDocumentIds.length === 0 || loading}
        >
          <Typography>Nuevo mail</Typography>
        </Button>
        <Button
          id="add-data-for-collection-button"
          color="primary"
          variant="contained"
          sx={{ height: 20, width: '100%', justifyContent: 'flex-start' }}
          startIcon={<NoteAltOutlined />}
          onClick={() => setOpenAddDataForCollectionDialog(true)}
          disabled={selectedDocumentIds.length === 0 || loading}
        >
          <Typography>Info recaudación</Typography>
        </Button>
        <Button
          color="primary"
          variant="contained"
          sx={{ height: 20, width: '100%', justifyContent: 'flex-start' }}
          startIcon={<AlarmOutlined />}
          onClick={() => setOpenAddCollectionProrogationDialog(true)}
          disabled={selectedDocumentIds.length === 0 || loading}
        >
          <Typography>Prórroga</Typography>
        </Button>
      </Stack>
      <StepperDialog
        open={openAddCollectionActionDialog}
        setOpen={setOpenAddCollectionActionDialog}
        handleClose={addCollectionActionResetFields}
        steps={addCollectionActionSteps}
      />
      <StepperDialog
        open={openAddDataForCollectionDialog}
        setOpen={setOpenAddDataForCollectionDialog}
        handleClose={addDataForCollectionResetFields}
        steps={addDataForCollectionSteps}
      />
      <StepperDialog
        open={openSendCollectionMailDialog}
        setOpen={setOpenSendCollectionMailDialog}
        handleClose={sendCollectionMailResetFields}
        steps={sendCollectionMailSteps}
      />
      <StepperDialog
        open={openAddCollectionProrogationDialog}
        setOpen={setOpenAddCollectionProrogationDialog}
        handleClose={addCollectionProrogationResetFields}
        steps={addCollectionProrogationSteps}
      />
    </Box>
  );
};

DebtorActions.propTypes = {
  debtor: MasterEntityType.isRequired,
  selectedDocumentIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  query: PropTypes.func.isRequired,
};

export default DebtorActions;
